import {
  DevicesQuery,
  FacilityTypesQuery,
  ManualIncidentCategoriesQuery,
  OutstandingIncidentsGuardsQuery,
  OutstandingIncidentsOperatorsQuery,
  PacsSourcesQuery,
  TechnicianQuery,
  TechniciansQuery,
} from '@/graphql/generated/operations'
import {
  FacilityFloorsQuery,
  FacilityNamesQuery,
  StandardOperatingProceduresFilterDataQuery,
} from '@/graphql/generated/operations'
import { incidentTypeOptions } from '@/redux/incidents/incidentsFilterOptions'

import { getArchivedLabelWithSN } from '../formats'

export interface OptionI<T = string> {
  label: string
  value: T
}

type Options = Array<OptionI>

export const getIncidentFacilityOptions = (
  facilitiesData: FacilityTypesQuery
): Options =>
  facilitiesData?.facilities?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.shortName})`,
    value: f?.node?.id,
  })) || []

export const getFacilityOptions = (
  facilitiesData: FacilityNamesQuery
): Options =>
  facilitiesData?.facilityNames?.edges.map((f) => getFacilityOption(f?.node)) ||
  []

export const getTechnicianFacilityOptions = (
  techData: TechnicianQuery
): Options =>
  techData?.technician?.facilities?.map((f) => getFacilityOption(f)) || []

export const getFacilityOption = (facilityNode: {
  name?: string
  shortName?: string
  archived?: boolean
  id: string
}): OptionI => ({
  label: getArchivedLabelWithSN(facilityNode),
  value: facilityNode?.id,
})

export const getFloorOptions = (floorsData: FacilityFloorsQuery): Options =>
  floorsData?.floors?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
    value: f?.node?.id,
  })) || []

export const getDeviceOptions = (devicesData: DevicesQuery): Options =>
  devicesData?.devices?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
    value: f?.node?.id,
  })) || []

export const getPacsOptions = (pacsData: PacsSourcesQuery): Options =>
  pacsData?.pacsSources?.edges.map((f) => ({
    label: `${f?.node?.name}`,
    value: f?.node?.id,
  })) || []

export const getSOPCreatorOptions = (
  sopData: StandardOperatingProceduresFilterDataQuery
): Options =>
  sopData?.standardOperatingProcedures.edges.map((s) => ({
    label: `${s?.node?.creator?.firstName} ${s?.node?.creator?.lastName}`,
    value: s?.node?.creator.id,
  })) || []

export const getTechnicianOptions = (data: TechniciansQuery): Options =>
  data?.technicians?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName} (${f?.node?.email})`,
    value: f?.node?.id,
  })) || []

export const getOutstandingIncidentsGuardsOptions = (
  data: OutstandingIncidentsGuardsQuery
): Options =>
  data?.guards?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName}`,
    value: f?.node?.id,
  })) || []

export const getOutstandingIncidentsOperatorsOptions = (
  data: OutstandingIncidentsOperatorsQuery
): Options =>
  data?.operators?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName}`,
    value: f?.node?.id,
  })) || []

export const getIncidentCategoryOptions = (
  data: ManualIncidentCategoriesQuery
) =>
  data?.manualIncidentCategories?.edges?.map((e) => ({
    label: e?.node?.name,
    value: e?.node?.id,
  })) || []

export const getIncidentTypeOptions = (data: ManualIncidentCategoriesQuery) =>
  sortOptions([...getIncidentCategoryOptions(data), ...incidentTypeOptions])

export const sortOptions = <T>(options: OptionI<T>[]): OptionI<T>[] => {
  return [...options].sort((a, b) => a.label.localeCompare(b.label))
}

export const getDefaultOption = <T>(
  option: OptionI<T> | OptionI<T>[]
): OptionI<T> | OptionI<T>[] | null => {
  if (Array.isArray(option)) {
    return option?.length ? option : null
  } else {
    return option || null
  }
}

export const mapOptions = <T>(arr: T[]): OptionI<T>[] =>
  arr.map((optionValue) => ({
    label: optionValue as string,
    value: optionValue,
  }))
