import { useSelector } from 'react-redux'

import { Role, RoleExtended } from '@/features/managed-accounts'
import { selectRole } from '@/redux/me/meSlice'

export const usePermissions = () => {
  const role = useSelector(selectRole)

  // Roles
  const isPM = role === Role.PROGRAM_MANAGER
  const isImplementer = role === Role.IMPLEMENTER
  const isReadOnly = role === Role.READ_ONLY_USER
  const isAdmin = isImplementer || isPM || isReadOnly
  const isOperator = role === Role.OPERATOR

  // Page access
  const shouldShowDashboardPage = isPM || isReadOnly
  const shouldShowSOPPage = !isImplementer
  const shouldShowEditMode = isImplementer || isPM || isReadOnly
  const shouldShowSettingsPage = isImplementer || isPM || isReadOnly
  const shouldShowIncidentRulesPage = isPM || isOperator

  // Incident actions
  const shouldEnableIncidentActions = !isReadOnly
  const shouldEnableIncidentRadioGuards = !isReadOnly
  const shouldEnableIncidentEmergencyCall = !isReadOnly
  const shouldEnableIncidentNote = !isReadOnly
  const shouldEnableSOPIncidentDrawerToggle = !isImplementer
  const shouldShowDownloadReportButton = isOperator || isPM

  //Modals
  const shouldEnableRemoveModal = !isReadOnly

  // Incident
  const shouldEnableCreateIncident = !isReadOnly
  const shouldShowCreateManualIncidentCategory = isPM

  // Device
  const shouldEnableCreateDevice = isImplementer
  const shouldEnableUpdateDevice = isImplementer || isPM
  const shouldEnableDeleteDevice = isImplementer
  // Associate Devices
  const shouldEnableAssociateDevices = isImplementer
  const shouldEnableDisassociateDevices = isImplementer
  const shouldEnableConfigureTailgateGrid = isImplementer || isPM
  // Facility
  const shouldEnableCreateFacility = isImplementer
  const shouldEnableUpdateFacility = isImplementer || isPM
  const shouldEnableDeleteFacility = isImplementer
  // Floor
  const shouldEnableCreateFloor = isImplementer
  const shouldEnableUpdateFloor = isImplementer
  // Checkpoint
  const shouldEnableCreateCheckpoint = isImplementer || isPM
  const shouldEnableUpdateCheckpoint = isImplementer || isPM
  const shouldEnableDeleteCheckpoint = isImplementer || isPM
  // TourPlan
  const shouldEnableCreateTourPlan = isImplementer || isPM
  const shouldEnableUpdateTourPlan = isImplementer || isPM
  const shouldEnableDeleteTourPlan = isImplementer || isPM
  // SOP
  const shouldEnableCreateSOP = isPM
  const shouldEnableUpdateSOP = isPM
  // Incident Rules
  const shouldEnableCreateIncidentsRule = isPM
  // Gateway Devices
  const shouldEnableCreateGateway = isImplementer
  const shouldEnableUpdateGateway = isImplementer || isPM
  const shouldEnableRestartGateway = isImplementer
  // PACS
  const shouldEnableCreatePACS = isImplementer
  // User, Technician
  const shouldEnableCreateUser = isImplementer || isPM
  const shouldEnableUpdateUser = isImplementer || isPM
  const shouldEnableCreateTechnician = isImplementer || isPM
  const shouldEnableUpdateTechnician = isImplementer || isPM
  // Setting - DatabaseReset, DeviceHealth Setting, Organization Settings
  const shouldEnableResetDatabase = isPM
  const shouldEnableUpdateDeviceHealthSettings = isImplementer || isPM
  const shouldEnableUpdateOrganizationSettings = isImplementer || isPM
  const shouldShowPACSSourceSecretsSettings = isImplementer

  //Case Management
  const shouldEnableCaseManagement = isPM || isOperator

  /**
   * This checks if a user has permission to add/edit/delete the specified role
   * crudRole - the role to perform the CRUD operation on
   */
  const hasCRUDPermission = (crudRole: Role | RoleExtended) => {
    if (role === Role.IMPLEMENTER) {
      return true
    } else if (role === Role.PROGRAM_MANAGER && crudRole !== Role.IMPLEMENTER) {
      return true
    }
    return false
  }

  return {
    hasCRUDPermission,
    isAdmin,
    shouldEnableAssociateDevices,
    shouldEnableConfigureTailgateGrid,
    shouldEnableCreateCheckpoint,
    shouldEnableCreateDevice,
    shouldEnableCreateFacility,
    shouldEnableCreateFloor,
    shouldEnableCreateGateway,
    shouldEnableCreateIncident,
    shouldEnableCreateIncidentsRule,
    shouldEnableCreatePACS,
    shouldEnableCreateSOP,
    shouldEnableCreateTechnician,
    shouldEnableCreateTourPlan,
    shouldEnableCreateUser,
    shouldEnableDeleteCheckpoint,
    shouldEnableDeleteDevice,
    shouldEnableDeleteFacility,
    shouldEnableDeleteTourPlan,
    shouldEnableDisassociateDevices,
    shouldEnableIncidentActions,
    shouldEnableIncidentEmergencyCall,
    shouldEnableIncidentNote,
    shouldEnableIncidentRadioGuards,
    shouldEnableRemoveModal,
    shouldEnableResetDatabase,
    shouldEnableRestartGateway,
    shouldEnableSOPIncidentDrawerToggle,
    shouldEnableUpdateCheckpoint,
    shouldEnableUpdateDevice,
    shouldEnableUpdateDeviceHealthSettings,
    shouldEnableUpdateFacility,
    shouldEnableUpdateFloor,
    shouldEnableUpdateGateway,
    shouldEnableUpdateOrganizationSettings,
    shouldEnableUpdateSOP,
    shouldEnableUpdateTechnician,
    shouldEnableUpdateTourPlan,
    shouldEnableUpdateUser,
    shouldEnableCaseManagement,
    shouldShowCreateManualIncidentCategory,
    shouldShowDashboardPage,
    shouldShowDownloadReportButton,
    shouldShowEditMode,
    shouldShowIncidentRulesPage,
    shouldShowPACSSourceSecretsSettings,
    shouldShowSOPPage,
    shouldShowSettingsPage,
  }
}
