import { Spinner as ChakraSpinner, Flex } from '@chakra-ui/react'

export const Spinner = ({ height = 12 }: { height?: number }) => {
  return (
    <Flex alignItems='center' flex={1} height={height} justifyContent='center'>
      <ChakraSpinner
        color='#298bbd'
        emptyColor='gray.300'
        size='md'
        thickness='4px'
      />
    </Flex>
  )
}
