import { IncidentsFilterDrawerMode } from '../hooks/useDrawerMode'
import { useIncidentFilterFormValues } from '../hooks/useIncidentFilterFormValues'
import { FeatureFlag, useFeatureFlags } from '@/hooks/useFeatureFlags'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Box, DrawerBody, Flex, Spacer, useDisclosure } from '@chakra-ui/react'

import { Button, FormInputSelectControl, PageSpinner } from '@/components/ui'
import { buildFilterCriteria } from '@/redux/incidents/conversion'
import {
  selectActiveFilter,
  setActiveFilterCriteria,
} from '@/redux/incidents/incidentsSlice'
import { hideScrollbarCss } from '@/styles/utils'
import { mixpanel } from '@/utils/analytics'

import { IncidentFiltersFormInputsI } from '../types/form'
import { SavedFilterI } from '../types/generic'
import { removeEmptyFilters } from '../utils/utils'
import { ActiveFilterBadge } from './ActiveFilterBadge'
import { CreateFilterModal } from './CreateFilterModal'
import { IncidentsFilterDrawerFooter } from './IncidentsFilterDrawerFooter'
import { UpdateFilterConfirmationModal } from './UpdateFilterConfirmationModal'

interface IncidentsFilterFormIProps {
  onClose: () => void
  onGoBack: () => void
  editedFilter: SavedFilterI | null
  drawerMode: IncidentsFilterDrawerMode
}

export const IncidentsFilterForm = ({
  onClose,
  onGoBack,
  editedFilter,
  drawerMode,
}: IncidentsFilterFormIProps) => {
  const dispatch = useDispatch()
  const activeFilter = useSelector(selectActiveFilter)

  const flags = useFeatureFlags()
  const isIncidentRulesEnabled = flags?.[FeatureFlag.incidentRules]
  const { control, handleSubmit, watch, setValue, formState } = useForm({
    reValidateMode: 'onChange',
  })

  const { defaultValues, options } = useIncidentFilterFormValues({
    watch,
    setValue,
    editedFilter,
  })

  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure()

  const onSubmit: SubmitHandler<IncidentFiltersFormInputsI> = async (
    values
  ) => {
    dispatch(setActiveFilterCriteria(buildFilterCriteria(values)))
    onClose()
    mixpanel.track('Incidents View: Filters Applied', {
      filters: removeEmptyFilters(values),
    })
  }

  if (defaultValues === null) {
    return <PageSpinner />
  }

  return (
    <>
      <DrawerBody css={hideScrollbarCss}>
        <Flex justifyContent='space-between' mb='3' mt='6' w='100%'>
          {activeFilter ? (
            <ActiveFilterBadge label={activeFilter?.name} />
          ) : (
            <Spacer />
          )}
          <Button
            isDisabled={editedFilter ? !formState.isDirty : false}
            onClick={onModalOpen}
            style={{
              height: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
              width: '120px',
            }}
          >
            Save Filter
          </Button>
        </Flex>
        <Box mb='3' mt='6'>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            dataTestId='incidentsPage_filterDrawer_facilityName'
            defaultValue={defaultValues.facilityName}
            id='facilityName'
            isClearable
            isMulti
            isSearchable
            label='Facility Name'
            options={options.facilityNameOptions}
            placeholder='Select Facility(-ies)'
          />
        </Box>
        <Box mb='3'>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            dataTestId='incidentsPage_filterDrawer_floorName'
            defaultValue={defaultValues.floorName}
            id='floorName'
            isClearable
            isMulti
            isSearchable
            label='Floor Name'
            options={options.floorNameOptions}
            placeholder='Select Floor(s)'
          />
        </Box>
        <Box mb='3'>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            dataTestId='incidentsPage_filterDrawer_deviceName'
            defaultValue={defaultValues.deviceName}
            id='deviceName'
            isClearable
            isMulti
            isSearchable
            label='Device Name'
            maxH={3}
            options={options.deviceNameOptions}
            placeholder='Select Device(s)'
          />
        </Box>
        <Box mb='3'>
          <FormInputSelectControl
            control={control}
            dataTestId='incidentsPage_filterDrawer_createdAt'
            defaultValue={defaultValues.createdAt}
            id='createdAt'
            isClearable
            isSearchable={false}
            label='Created At'
            options={options.createdAtOptions}
            placeholder='Select Time Range'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            dataTestId='incidentsPage_filterDrawer_incidentStatus'
            defaultValue={defaultValues.incidentStatus}
            id='incidentStatus'
            isClearable
            isMulti
            isSearchable
            label='Incident Status'
            options={options.incidentStatusOptions}
            placeholder='Select Incident Status(-es)'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            control={control}
            dataTestId='incidentsPage_filterDrawer_sourceType'
            defaultValue={defaultValues.sourceType}
            id='sourceType'
            isClearable
            isMulti
            isSearchable
            label='Source Type'
            options={options.sourceTypeOptions}
            placeholder='Select Source Type'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            control={control}
            dataTestId='incidentsPage_filterDrawer_incidentType'
            defaultValue={defaultValues.incidentType}
            id='incidentType'
            isClearable
            isMulti
            isSearchable
            label='Incident Type'
            options={options.incidentTypeOptions}
            placeholder='Select Incident Type'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            dataTestId='incidentsPage_filterDrawer_priority'
            defaultValue={defaultValues.priority}
            id='priority'
            isClearable
            isMulti
            isSearchable
            label='Priority'
            options={options.priorityOptions}
            placeholder='Select Priority(-ies)'
          />
        </Box>
        {isIncidentRulesEnabled && (
          <Box mb={3}>
            <FormInputSelectControl
              control={control}
              dataTestId='incidentsPage_filterDrawer_mute'
              defaultValue={defaultValues.muted}
              id='muted'
              isClearable
              isSearchable
              label='Muted'
              options={options.mutedOptions}
              placeholder='Select incidents that have been muted or raised'
            />
          </Box>
        )}
      </DrawerBody>
      {drawerMode === IncidentsFilterDrawerMode.DETAIL && (
        <IncidentsFilterDrawerFooter
          activeFilter={activeFilter}
          isApplyDisabled={!formState.isDirty}
          onApplyFilters={handleSubmit(onSubmit)}
        />
      )}
      {isModalOpen &&
        (drawerMode === IncidentsFilterDrawerMode.UPDATE ? (
          <UpdateFilterConfirmationModal
            editedFilter={editedFilter}
            incidentFiltersFormValues={watch()}
            isOpen={isModalOpen}
            onClose={() => {
              onModalClose()
              onGoBack()
            }}
          />
        ) : (
          <CreateFilterModal
            incidentFiltersFormValues={watch()}
            isOpen={isModalOpen}
            onClose={onModalClose}
          />
        ))}
    </>
  )
}
